import { graphql } from "gatsby";
import { css } from "linaria";
import React from "react";
import { BlogContentRenderer } from "../../components/Blog/SingleBlog/BlogContentRenderer/BlogContentRenderer";
import { BlogHeader } from "../../components/Blog/SingleBlog/BlogHeader/BlogHeader";
import { ContentRenderer } from "../../components/Shared/ContentRenderer/ContentRenderer";
import Layout from "../../components/Shared/Layout/Layout";
import { Blog } from "../../lib/model/Blog";
import { Notice } from "../../lib/model/Home";

export interface NoticeTemplateProps {
  className?: string;
  data: {
    strapiInformativeNotice: Notice.Informativenotice;
  };
}

export const query = graphql`
  query getNotice($slug: String!) {
    strapiInformativeNotice(slug: { eq: $slug }) {
      slug
      shortInfo
      image {
        childImageSharp {
          fluid (maxWidth: 1500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      date
      content
    }
  }
`;

const NoticeTemplateInner = (props: NoticeTemplateProps) => {
  const {
    data: { strapiInformativeNotice: notice },
  } = props;

  return (
    <Layout>
      <div className="container w-full md:w-2/3 xl:w-1/2 mx-auto px-6">
        <BlogHeader
          title={notice.shortInfo}
          image={notice.image.childImageSharp.fluid}
          date={notice.date}
        />
        <div className="content mt-8 md:mt-12 xl:mt-16">
          <ContentRenderer source={notice.content} />
        </div>
      </div>
    </Layout>
  );
};

export default NoticeTemplateInner;
